import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip,
  Box
} from '@material-ui/core';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { getUserList, deleteUser } from 'src/redux/slices/user';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths' ;
// components
import Page from 'src/components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { UserListHead, UserListToolbar, UserMoreMenu } from 'src/components/_dashboard/user/list';
import  ProductToolbar  from 'src/components/_dashboard/user/list/ProductToolbar.js';
import  ProductsSearch  from 'src/components/_dashboard/blog/ProductsSearch.js';
import  ProductsMenu  from 'src/components/_dashboard/user/list/ProductsMenu.js';
import axios from 'axios';
import {COMPANY_NAME, REST_API, REST_API_END_POINT} from 'src/constants/Defaultvalues';
import moment from 'moment';
import MaterialInventoryMenu from 'src/components/_dashboard/user/list/MaterialInventoryMenu';
import MaterialInventoryToolbar from 'src/components/_dashboard/user/list/MaterialInventoryToolbar';
import InventoryAuditPage from './InventoryAuditPage';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'total_qty', label: 'Stock', alignRight: false },
  { id: 'min', label: 'Min Value', alignRight: false },
  { id: 'max', label: 'Max Value', alignRight: false },
  // { id: 'addedby', label: 'Added By', alignRight: false },
  // { id: 'addedon', label: 'Added On', alignRight: false },
//   { id: 'status', label: 'Status', alignRight: false },
  { id:'options', label: '', alignRight: false},      
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}


export default function InventoryAuditList(props) {
  const theme = useTheme();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [ProductList, setProductList] = useState([]);
  const location = useLocation();
  const receivedData = location.state?.data;
  const userData = JSON.parse(localStorage.getItem('user'))
  const explodedPermissions =userData?.exploded_permissions

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  useEffect( async () => {
    try {
      const response = await axios.post(`${REST_API_END_POINT}product/get-all-materials`);
      const productListData = response.data.results;
      if(productListData){
        setProductList(productListData);
      }
      else{
        setProductList([]);
      }
    }
      catch (error) {
      console.error("Error fetching data:", error);
    }
    finally{
      setLoading(false);
    }
  }, [receivedData]);

  // ...
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = slicedData?.map((n) => n.id);
      const newSelectedNames = slicedData?.map((n) => n.name);
      setSelected(newSelecteds);
      setSelectedNames(newSelectedNames);
      return;
    }
    setSelected([]);
    setSelectedNames([]);
  };

  const handleClick = (event, id,name) => {
    const selectedIndex = selected?.indexOf(id);
    const selectedNameIndex = selectedNames?.indexOf(name);
    let newSelected = [];
    let newselectedNames = [];
    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, id);
      newselectedNames = newselectedNames?.concat(selectedNames, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
      newselectedNames = newselectedNames?.concat(selectedNames?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
      newselectedNames = newselectedNames?.concat(selectedNames?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(selected?.slice(0, selectedIndex), selected?.slice(selectedIndex + 1));
      newselectedNames = newselectedNames?.concat(selectedNames?.slice(0, selectedNameIndex), selectedNames?.slice(selectedNameIndex + 1));
    }
    setSelected(newSelected);
    setSelectedNames(newselectedNames);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelected([]);
    setSelectedNames([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0)
  };

  const handleDeleteUser = (userId) => {
    dispatch(deleteUser(userId));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ProductList.length) : 0;
  const filteredUsers = applySortFilter(ProductList, getComparator(order, orderBy), filterName);
  const slicedData = filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title={`Inventory Audit : List | ${COMPANY_NAME}`}>
      <Container>
        <Card>
        <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
          <MaterialInventoryToolbar 
          numSelected={selected?.length} 
          filterName={filterName} 
          onFilterName={handleFilterByName} 
          selectedName={selectedNames} 
          selected={selected} 
          onDelete={() => {setSelected([]);setPage(0);}}
          item="Inventory"
          />
            
          </Box>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
              <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={slicedData?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                {loading ? (
                      <LoadingScreen
                        sx={{ top: 0,
                              left: 0,
                              width: 1,
                              zIndex: 9999,
                              position: 'fixed',
                              }}
                      />
                    ) : (
                      <>                  
                  {slicedData?.map((row) => {
                    let { id,thumbnail, name, timestamp,min_value,max_value, status, price,tax, authorized_by,total_qty, isVerified } = row;
                    let statusText;
                    (status === 1) ? statusText = "Active" : statusText = "Inactive";
                    let image = `${REST_API}build/images/products/${thumbnail}`;
                    let addedon = moment.unix(timestamp).format('MM/DD/YYYY');
                    const isItemSelected = selected?.indexOf(id) !== -1;
                                    
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell  align="center">
                          <Typography sx={{color:(total_qty<=0) ? theme.palette.error.main : null}}>{total_qty}</Typography>
                        </TableCell>
                        <TableCell  align="center">
                        {min_value}
                        </TableCell>
                        <TableCell  align="center">
                        {max_value}
                        </TableCell>
                        {/* <TableCell align="center">{authorized_by}</TableCell>
                        <TableCell align="center">{addedon}</TableCell> */}
                        {/* <TableCell align="center">
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={(status === 1 && 'info') || 'error'}
                          >
                            {sentenceCase(statusText)}
                          </Label>
                        </TableCell> */}

                        <TableCell align="center">
                            <Button 
                            disabled={explodedPermissions?.some(permission => ['Inventory Audit'].includes(permission)) ? false : true}
                            variant='contained' 
                            component={RouterLink} 
                            to={`${PATH_DASHBOARD.inventoryAudit.auditPage}?id=${id}`}> Audit </Button>
                          {/* <MaterialInventoryMenu onDelete={() => handleDeleteUser(id)} userName={name} id={id} status={status}/> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  </>
                    )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} msg="Inventory items"/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
