import * as Yup from 'yup';
import { filter, truncate } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  TextField,
  Box,
  CircularProgress
} from '@material-ui/core';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { getUserList, deleteUser } from 'src/redux/slices/user';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// components
import Page from 'src/components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { UserListHead, UserListToolbar, UserMoreMenu } from 'src/components/_dashboard/user/list';
import  ProductToolbar  from 'src/components/_dashboard/user/list/ProductToolbar.js';
import  ProductsSearch  from 'src/components/_dashboard/blog/ProductsSearch.js';
import  ProductsMenu  from 'src/components/_dashboard/user/list/ProductsMenu.js';
import axios from 'axios';
import {REST_API, REST_API_END_POINT} from 'src/constants/Defaultvalues';
import moment from 'moment';
import MaterialInventoryMenu from 'src/components/_dashboard/user/list/MaterialInventoryMenu';
import MaterialInventoryToolbar from 'src/components/_dashboard/user/list/MaterialInventoryToolbar';
import Modal from 'src/components/_dashboard/blog/ProjectModel';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { stubTrue } from 'lodash';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'barcode', label: 'Barcode', alignRight: false },
  { id: 'reason', label: 'Reason', alignRight: false },
  { id: 'authorized_by', label: 'Done By', alignRight: false },
  { id: 'timestamp', label: 'Done On', alignRight: false },     
];

// ----------------------------------------------------------------------

export default function InventoryAuditLog({refreshData}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const editId = parseInt(id);
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [filterSource, setFilterSource] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [inventoryLogData, setInventoryLogData] = useState([]);
  const userData = JSON.parse(localStorage.getItem('user'))
  const userId = userData.user_id

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  useEffect( async () => {
    setLoading(true)
    try {
      const LogResponse = await axios.post(`${REST_API_END_POINT}product/fetch-inventory-audit-log`,{editId});
      const LogResponseData = LogResponse.data?.logData;
      if(LogResponseData){
        console.log('LogResponseData-----',LogResponseData);
        setInventoryLogData(LogResponseData)
      } else{
        setInventoryLogData([]);
      }
    }
      catch (error) {
      console.error("Error fetching data:", error);
    }
    finally{
      setLoading(false);
    }
  }, [refreshData]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function applySortFilter(array, comparator, query, source) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => _user.barcode.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    if (source) {
      if (source === 'Inventory') {
        return filter(array, (_user) => _user.source === 0);
      }
      else if (source === 'Vendor') {
        return filter(array, (_user) => _user.source === 1);
      }
    }
    return stabilizedThis.map((el) => el[0]);
  }
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = slicedData?.map((n) => n.id);
      const newSelectedNames = slicedData?.map((n) => n.name);
      setSelected(newSelecteds);
      setSelectedNames(newSelectedNames);
      return;
    }
    setSelected([]);
    setSelectedNames([]);
  };

  const handleClick = (event, id,name) => {
    const selectedIndex = selected?.indexOf(id);
    const selectedNameIndex = selectedNames?.indexOf(name);
    let newSelected = [];
    let newselectedNames = [];
    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, id);
      newselectedNames = newselectedNames?.concat(selectedNames, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
      newselectedNames = newselectedNames?.concat(selectedNames?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
      newselectedNames = newselectedNames?.concat(selectedNames?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(selected?.slice(0, selectedIndex), selected?.slice(selectedIndex + 1));
      newselectedNames = newselectedNames?.concat(selectedNames?.slice(0, selectedNameIndex), selectedNames?.slice(selectedNameIndex + 1));
    }
    setSelected(newSelected);
    setSelectedNames(newselectedNames);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelected([]);
    setSelectedNames([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0)
  };

  const handleFilterBySource = (event) => {
    setFilterSource(event);
    setPage(0)
  };

  const handleDeleteUser = (userId) => {
    dispatch(deleteUser(userId));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - inventoryLogData?.length) : 0;
  const filteredUsers = applySortFilter(inventoryLogData, getComparator(order, orderBy), filterName, filterSource);
  const slicedData = filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const isUserNotFound = filteredUsers?.length === 0;

  return (
    <>
      <Container>
        <Stack sx={{my:2}}>
        <Typography variant='h4'>Inventory Audit Log</Typography>
        </Stack>
        <Card>
          {/* <MaterialInventoryToolbar 
          numSelected={selected?.length} 
          filterName={filterName} 
          onFilterName={handleFilterByName}
          filterSource={filterSource}
          onFilterSource={handleFilterBySource}
          selectedName={selectedNames} 
          selected={selected} 
          onDelete={() => {setSelected([]);setPage(0);}}
          item="barcode"
          /> */}
          <div style={{marginTop:10}}> </div>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
              <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={slicedData?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                {loading ? (
                  // <CircularProgress color="primary" variant="indeterminate" />
                  <Stack sx={{alignItems:'center', justifyContent:'center', minWidth: 800}}>
                    <LoadingScreen />
                  </Stack>
                ) : (
                  <>
                  {slicedData?.map((row) => {
                    let { id, barcode, timestamp, authorized_by,reason } = row;
                    const isWithinLast24Hours = (timestamp) => {
                      const twentyFourHoursAgo = moment().subtract(24, 'hours');
                      const timestampMoment = moment.unix(timestamp); // Convert timestamp to moment object
                      return timestampMoment.isAfter(twentyFourHoursAgo);
                    };
                    const withinLast24Hours = isWithinLast24Hours(timestamp);
                    const isItemSelected = selected?.indexOf(id) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell>
                          {/* <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id,name)} /> */}
                          {withinLast24Hours && <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={ 'info' }
                          >
                            New
                          </Label>}
                        </TableCell>
                        <TableCell align="center">
                          {/* <Barcode value={barcode} width={0.5} height={30} fontSize={10}/> */}
                          <Typography variant="body">
                          {barcode}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">{reason}</TableCell>
                        <TableCell align="center">{authorized_by}</TableCell>
                        <TableCell align="center">{moment.unix(timestamp).format('DD-MM-YYYY')}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  </>
                    )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} msg="Item"/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
