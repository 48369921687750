import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import { useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import QRCode from 'react-qr-code'
import ReactToPrint from 'react-to-print'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import {
  PrintOutlined,
} from '@material-ui/icons'
// material
import { useTheme } from '@material-ui/core/styles'
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'notistack'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { BASE_URL, COMPANY_NAME } from 'src/constants/Defaultvalues'

// routes

// ----------------------------------------------------------------------



export default function FinishedProductmenu({ id,url,useIcon }) {
  const ref = useRef(null)
  const componentRef = useRef(null)
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  let user = JSON.parse(localStorage.getItem('user'))

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <IconButton ref={ref} color='#ffffff'>
          {/* {useIcon ? <PrintOutlined /> : <Icon icon={moreVerticalFill} width={20} height={20} />} */}
          <PrintOutlined />
          {/* {useIcon && <Typography variant="body2" sx={{ marginLeft: 1 }}>Print</Typography>} */}
          </IconButton>
        )}
        content={() => componentRef.current}
      />
    <div style={{ display: 'none' }}>
      <div
        ref={componentRef}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 120,
        }}
      >
       <h3 >{COMPANY_NAME}</h3>
        <QRCode
          value={`${url}${id}`}
          size={300}
          style={{display:'flex',justifyContent:'center',alignItems:'center',mt:40}}
          bgColor={'#ffffff'}
          fgColor={'#000000'}       
          includeMargin={false}
        />
      </div>
    </div>
  </>
    // <>
    //   <IconButton ref={ref} onClick={() => setIsOpen(!isOpen)}>
    //   {/* {useIcon ? <PrintOutlined /> : <Icon icon={moreVerticalFill} width={20} height={20} />} */}
    //   <PrintOutlined />
    //   {useIcon && <Typography variant="body2" sx={{ marginLeft: 1 }}>Print</Typography>}
    //   </IconButton>

    //   <Menu
    //     open={isOpen}
    //     anchorEl={ref.current}
    //     onClose={() => setIsOpen(!isOpen)}
    //     PaperProps={{
    //       sx: { width: 200, maxWidth: '100%' },
    //     }}
    //     anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    //     transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    //   >
    //     <ReactToPrint
    //       trigger={() => (
    //         <MenuItem>
    //           <ListItemIcon>
    //             <PrintOutlined />
    //           </ListItemIcon>
    //           <ListItemText
    //             primary="Print"
    //             primaryTypographyProps={{ variant: 'body2' }}
    //           />
    //         </MenuItem>
    //       )}
    //       content={() => componentRef.current}
    //     />
    //   </Menu>
    //   <div style={{ display: 'none' }}>
    //     <div
    //       ref={componentRef}
    //       style={{
    //         display: 'flex',
    //         flexDirection: 'column',
    //         alignItems: 'center',
    //         justifyContent: 'center',
    //         marginTop: 120,
    //       }}
    //     >
    //      <h3 >{COMPANY_NAME}</h3>
    //       <QRCode
    //         value={`${url}${id}`}
    //         size={300}
    //         style={{display:'flex',justifyContent:'center',alignItems:'center',mt:40}}
    //         bgColor={'#ffffff'}
    //         fgColor={'#000000'}       
    //         includeMargin={false}
    //       />
    //     </div>
    //   </div>
    // </>
  )
}
