import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import twitterFill from '@iconify/icons-eva/twitter-fill'
import linkedinFill from '@iconify/icons-eva/linkedin-fill'
import facebookFill from '@iconify/icons-eva/facebook-fill'
import instagramFilled from '@iconify/icons-ant-design/instagram-filled'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import { sentenceCase } from 'change-case'
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'

import {
  Box,
  Card,
  Grid,
  Avatar,
  Tooltip,
  Divider,
  Stack,
  Typography,
  IconButton,
  AvatarGroup,
} from '@material-ui/core'
// utils
import { fShortenNumber } from 'src/utils/formatNumber'
//
import SvgIconStyle from 'src/components/SvgIconStyle'
import Label from 'src/components/Label'
import { useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { Message, MessageSharp, RequestQuote } from '@material-ui/icons'
import moment from 'moment'
import { getAgoString } from 'src/common/Helper'
import { useSnackbar } from 'notistack'
import { Link } from 'react-router-dom'
import { empty } from 'src/Helpers/Common'
import { COMPANY_LOGO } from 'src/constants/Defaultvalues'
// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  paddingTop: 'calc(100% * 9 / 16)',
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    //  backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderTopRightRadius: theme.shape.borderRadiusMd,
    // backgroundColor: alpha(theme.palette.primary.darker, 0.72)
  },
}))

const CoverImgStyle = styled('img')({
  top: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  //    background: `url(${src}) center/cover no-repeat`
})

// ----------------------------------------------------------------------

function InfoItem(number) {
  return (
    <Grid item xs={4}>
      <Typography
        variant="caption"
        sx={{ mb: 0.5, color: 'text.secondary', display: 'block' }}
      >
        Follower
      </Typography>
      <Typography variant="subtitle1">{fShortenNumber(number)}</Typography>
    </Grid>
  )
}

OfficialCard.propTypes = {
  card: PropTypes.object.isRequired,
  team_member: PropTypes.array.isRequired,
}

export default function OfficialCard({ card, team_member, communityId,clientId, communityName, CommunityProjects,quotationView,proposalView }) {
  const theme = useTheme()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  let user = JSON.parse(localStorage.getItem('user'))
  const userType = user.user_type
  const explodedPermissions = user?.exploded_permissions
  const userId = user.user_id
  const {
    image,
    message,
    timestamp,
    id,
    name,
    status,
    request,
    people,
    description,
    subject,
    submission_deadline,
    msg_count,
    proposal_count,
    participation_ends,
    services,
    accept_status,
    thumbnail,
    cover_image,
    invoice_total,
    partners,
    customer_pic,
    community_name,
    installationStatus,
   customer_name,
   awarded,
   quotation_status,
   statusRfp

  } = card
  let customer_image = partners?.filter((item)=>item?.profile_pic != 'avatar.jpg')
  let accepted_customer_image = partners?.filter((item)=>{(item?.profile_pic != 'avatar.jpg') && (item?.status == 1)})?.map((data)=>{
    return data?.profile_pic
  })
  // console.log('customer_image--',customer_image);
  // console.log('accepted_customer_image--',accepted_customer_image);
//  const currentUnix = moment().unix()
//  console.log(currentUnix,'))))))))))))))))')
//   console.log(submission_deadline,'$$$$$$$$$$$$$$$$')
  const updatedAt = moment.unix(timestamp).format('YYYY-MM-DD')
  //status color
  const getStatusColorAndLabel = (status) => {
    switch (status) {
      case 1:
        return { color: 'warning', label: 'Status: Draft' }
      case 2:
        return { color: 'info', label: 'Status: Open' }
      case 3:
        return { color: 'primary', label: 'Status: Accepted' }
      case 4:
        return { color: 'success', label: 'Status: In Job' }
      case 5:
        return { color: 'warning', label: 'Status: Closed' }
      case 6:
        return { color: 'error', label: 'Status: Rejected' }
      default:
        return { color: 'default', label: 'Status: Open' }
    }
  }
  const getStatusColorAndLabelQuickbid = (status) => {
    switch (status) {
      case 1:
        return { color: 'warning', label: 'Status: Draft' }
      case 2:
        return { color: 'info', label: 'Status: Open' }
      case 3:
        return { color: 'primary', label: 'Status: Evaluating' }
      case 4:
        return { color: 'success', label: 'Status: Accepted' }
      case 5:
        return { color: 'warning', label: 'Status: Closed' }
      case 6:
        return { color: 'error', label: 'Status: Rejected' }
      default:
        return { color: 'default', label: 'Status: Open' }
    }
  }
  const getStatusColorAndLabelForProposal = (status) => {
    switch (status) {
      case 1:
        return { color: 'info', label: 'Status: Open' }
      case 2:
        return { color: 'success', label: 'Status: Awarded' }
      case 3:
        return { color: 'error', label: 'Status: Rejected' }
      case 4:
        return { color: 'success', label: 'Status: Accepted' }
      case 5:
        return { color: 'warning', label: 'Status: Closed' }
      case 6:
        return { color: 'error', label: 'Status: Rejected' }
      default:
        return { color: 'info', label: 'Status: Open' }
    }
  }

  const option = [
    { id: 0, value: 'Inactive' },
    { id: 1, value: 'New' },
    { id: 2, value: 'Open' },
    { id: 3, value: 'Completed' },
    { id: 4, value: 'In Testing' },
    { id: 5, value: 'Accepted' },
    { id: 6, value: 'Rejected' },
    { id: 7, value: 'Verified' },
    { id: 6, value: 'Reopen' },
  ]
  const colors = [
    'error',
    'primary',
    'secondary',
    'success',
    'warning',
    'success',
    'error',
    'success',
    'error',
  ]

  return (
    <Card
      id={id}
      onClick={() => {
        //    navigate(PATH_DASHBOARD.blog.viewproject)
      }}
    >
      <CardMediaStyle>
        {/* <IconButton
        sx={{
          top: 8,zIndex:500,color:theme.palette.grey.A100,
          right: 5,
          position: 'absolute'
        }}
      >
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton> */}
        {/* <SvgIconStyle
          color="paper"
          src={image}
          sx={{
            width: 144,
            height: 62,
            zIndex: 10,
            bottom: -26,
            position: 'absolute'
          }}
        /> */}

        <AvatarGroup
          max={7}
          sx={{ '& .MuiAvatar-root': { width: 62, height: 62, zIndex: 10 } }}
        >
          {team_member?.length > 0 &&
            team_member?.map((person) => {
              return (
                <Tooltip title={person?.member_name}>
                  <Avatar
                    key={person.id}
                    sx={{
                      width: 30,
                      height: 30,
                      position: 'absolute',
                      transform: 'translateY(-50%)',
                    }}
                    alt={person?.member_name}
                    src={person?.profile_pic}
                  />
                </Tooltip>
              )
            })}
        </AvatarGroup>
        <CoverImgStyle
          alt="cover"
          src={((customer_pic && (customer_pic != 'avatar.jpg')) ? customer_pic : COMPANY_LOGO)} //thumbnail ? thumbnail : 
          // ((accepted_customer_image?.length > 0) ? accepted_customer_image[0] : customer_image[0]?.profile_pic || '/static/mock-images/avatars/avatar_default1.jpg')} //thumbnail ? thumbnail : 
          // communityName && (cover_image ? cover_image : '/static/mock-images/avatars/avatar_default1.jpg') || 
        />
        <Stack sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ position: 'absolute', top: 8, left: 10, zIndex: 10 }}>
         {CommunityProjects ?
         <Label
         variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={colors[status]} >
          {option.find(opt=>opt.id===status).value} 
         </Label>
         :
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={quotationView?getStatusColorAndLabelQuickbid(quotation_status).color:proposalView?getStatusColorAndLabelForProposal(userType!=3?(awarded?2:1):statusRfp).color:getStatusColorAndLabel(status).color}
          >
            {quotationView ? getStatusColorAndLabelQuickbid(quotation_status).label : proposalView ? getStatusColorAndLabelForProposal(userType!=3?(awarded?2:1):statusRfp).label : getStatusColorAndLabel(status).label}
          </Label>}
        </Typography>
        {!quotationView && !proposalView ?<Typography sx={{ position: 'absolute', top: 8, right: 10, zIndex: 10 }}>
         {!CommunityProjects ?
        community_name?<Label
         variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={'warning'}  >
          
          <Tooltip title={community_name}>
                              <Typography
                                variant="subtitle2"
                                sx={{
                                
                                  fontSize: '14px',
                                  cursor: 'pointer',
                                  display: 'inline-block',
                                  maxWidth: 100, // Adjust as necessary
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {community_name}
                                </span>
                              </Typography>
                            </Tooltip>
         </Label>
         :<Label
         variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={ 'error'} >
           No project
         </Label>:""
          }
        </Typography>:""}
        {!CommunityProjects && !proposalView && customer_name && (
            <Typography sx={{ position: 'absolute', bottom: 8, left: 10, zIndex: 10 }}>
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color="secondary"
              >
                <Tooltip title={customer_name}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: '14px',
                      cursor: 'pointer',
                      display: 'inline-block',
                      maxWidth: "100%", // Adjust as necessary
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <span
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {customer_name}
                    </span>
                  </Typography>
                </Tooltip>
              </Label>
            </Typography>
          )}
        </Stack > 
      </CardMediaStyle>
      {/* {userType ==3 && accept_status ==2&& (status !==6) && (
        <Typography
        color={theme.palette.error.light}
        variant="body2"
        align="center"
        // sx={{ color: 'text.secondary' }}
      >
          Sorry, You are not eligible to view the request
           because you have not accepted the request
            on or before the Evaluation Stage
      </Typography>
      
      )} */}
      <Stack sx={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
      <Tooltip title={name}><Typography
        onClick={
          CommunityProjects ?
          (((explodedPermissions?.some(permission => ['Add Jobs','Edit Jobs','Activate/Deactivate Jobs','Delete Jobs','Manage Job Cost'].includes(permission))) || (userType == 3))
            ? () => navigate(PATH_DASHBOARD.project.viewproject+id)
            : ()=>enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' }))
          :
          (((explodedPermissions?.some(permission => ['Create Request','Edit Request','Delete request','Add vendor to request','Add client to request'].includes(permission))) || (userType === 3 && status !== 6)) 
            ? userType !== 3 
            ?empty(installationStatus)? quotationView?quotation_status!=6?() => navigate(`/dashboard/quick-bid/view/${id}`,{state:true}): ()=>enqueueSnackbar("Access Denied: Rejected Quotation", { variant: 'error' }):
            proposalView?(proposal_count===0?() => navigate(`/dashboard/rfp/view/${id}`,{state:true}):()=>enqueueSnackbar("Access Denied: Proposals Submited", { variant: 'error' })
         ) :() => navigate(`/dashboard/official-request/view/${id}`) //,{state:true}
             : ()=>enqueueSnackbar("Access Denied: Moved to installation Phase", { variant: 'error' })
            : quotationView?() => navigate(`/dashboard/quick-bid/view/${id}`): proposalView?((statusRfp===null||statusRfp===1) ?()=> navigate(`/dashboard/rfp/view/${id}`,{state:true}):()=>enqueueSnackbar("Access Denied: Proposal submission closed", { variant: 'error' })) :() => navigate(`/dashboard/official-request/view/${id}`) 
            : ()=>enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' }))
        
          }
        variant="subtitle1"
        
        sx={{
          mt: 3,
          ml:2,
          maxWidth:userType!=3 && !CommunityProjects?150:"100%",
          cursor: 'pointer',
          display: 'inline-block',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: theme.palette.primary.main,
          '&:hover': {
            cursor: CommunityProjects ?
            ((explodedPermissions?.some(permission => ['Add Jobs','Edit Jobs','Activate/Deactivate Jobs','Delete Jobs','Manage Job Cost'].includes(permission)))
              ? 'pointer'
              : 'default' )
            :
            (((explodedPermissions?.some(permission => ['Create Request','Edit Request','Delete request','Add vendor to request','Add client to request'].includes(permission))) || (userType === 3 && status !== 6)) && !(userType === 3 && accept_status === 2)
              ? 'pointer'
              : 'default')
          },
        }}
      >
        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {name}
                                </span> 
      </Typography>
      </Tooltip>
      {console.log("######quotationView",quotationView)}
      {((userType!=3 && !CommunityProjects )||(proposalView))&&
      <Typography
        onClick={
          CommunityProjects ?
          (((explodedPermissions?.some(permission => ['Add Jobs','Edit Jobs','Activate/Deactivate Jobs','Delete Jobs','Manage Job Cost'].includes(permission))) || (userType == 3))
            ? () => navigate(PATH_DASHBOARD.project.viewproject+id)
            :()=> enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' }))
          :
          (((explodedPermissions?.some(permission => ['Create Request','Edit Request','Delete request','Add vendor to request','Add client to request'].includes(permission))) || (userType === 3 && status !== 6)) && !(userType === 3 && accept_status === 2)
            ?quotationView? () => navigate(`/dashboard/quick-bid/view/${id}`):proposalView?() => navigate(`/dashboard/rfp/view/${id}`):() => navigate(`/dashboard/official-request/view/${id}`)
            :()=> enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' }))
        }
        variant="subtitle1"
        
        sx={{
          mt: 3,
          mr:2,
          color: theme.palette.primary.main,
       
          '&:hover': {
            cursor: CommunityProjects ?
            ((explodedPermissions?.some(permission => ['Add Jobs','Edit Jobs','Activate/Deactivate Jobs','Delete Jobs','Manage Job Cost'].includes(permission)))
              ? 'pointer'
              : 'default' )
            :
            (((explodedPermissions?.some(permission => ['Create Request','Edit Request','Delete request','Add vendor to request','Add client to request'].includes(permission))) || (userType === 3 && status !== 6)) && !(userType === 3 && accept_status === 2)
              ? 'pointer'
              : 'default')
          },
        }}
      >
       {quotationView||proposalView?"Details": "RFQ Details"}
      </Typography>}
      </Stack>
      {/* <Typography
        variant="body2"
        align="center"
        sx={{ color: 'text.secondary' }}
      >
        {description}
      </Typography> */}
      <Typography
        variant="overline"
        align="center"
        sx={{ color: 'text.secondary' }}
      ></Typography>

      {/* <Divider /> */}

      <Typography
        variant="subtitle1"
        align="center"
        sx={{ color: 'text.secondary', mt: 2 }}
      >
       Last updated {getAgoString(timestamp)}
      </Typography>
      
      <Grid
        container
        sx={{
          textAlign: 'center',
          justifyContent: 'flex-end',
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          pb: 2,
          pr: 2,
        }}
      >
        {invoice_total ?  
          <Typography
            variant="body2"
            align="center"
            sx={{ color: 'text.secondary' }}
          >
            <RequestQuote sx={{ width: 20, height: 20, color:status===4?theme.palette.success.main:theme.palette.error.main}} />
            {invoice_total.toFixed(2)}
          </Typography>
        : null }
      </Grid>
    </Card>
  )
}
