export const GOOGLE_API_KEY = "AIzaSyB0VFLrPEaYGqP4FVdGDEeUUN6Ks9AZPao"; 
// export const GOOGLE_API_KEY = "AIzaSyBe6vrykAsiKjwIHWOUcfH1Li4VT-2LHTg";

// export const REST_API_END_POINT = "http://localhost:4000/webservice/";
// export const REST_API = "http://localhost:4000/";
// export const BASE_URL = "http://localhost:3000/";

// dev.signwisesolutions.com
// export const BASE_URL = "https://dev.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://dev-api.signwisesolutions.com/webservice/";
// export const REST_API = "https://dev-api.signwisesolutions.com/";

// my.signwisesolutions.com
export const REST_API_END_POINT = "https://api.signwisesolutions.skyniche.website/webservice/";
export const REST_API = "https://api.signwisesolutions.skyniche.website/";
export const BASE_URL = "https://my.signwisesolutions.com/";

const user =  JSON.parse(localStorage.getItem('user'))

export const COMPANY_LOGO = user?.company_logo
export const COMPANY_NAME = user?.company_name

export const VERSION = '2.7';

export const PUBNUB_PUBLISH_KEY= 'pub-c-014b4ee8-2c24-46d0-ae01-4a933c3fee2e'
export const PUBNUB_SUBSCRIBE_KEY= 'sub-c-53f81de7-6961-4d0e-87bb-f3defdef49a0'
export const PUBNUB_SECRET_KEY="sec-c-NTY0NDYwZDEtN2M3MC00Y2YwLWE4ZmItMzdmMjc5MTY1YTNl"

export const UNITS = [{id:1,name:'Square feet'}, {id:2,name:'Lineal feet'}, {id:3,name:'Square Inches'}, {id:4,name:'Unit'}]